<template>
  <div>
    <b-card>
      <b-tabs
        @input="handleTabChange"
      >
        <b-tab
          v-for="(tab, index) in tabs"
          :key="index"
          :title="tab.title"
        >
          <hr>
          <component
            :is="tab.component"
            v-if="tab.isLoaded"
            :title="tab.title"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { BCard, BTabs, BTab } from 'bootstrap-vue'

import MessagesHistoryAdminReceive from './MessagesHistoryAdminReceive.vue'
import MessagesHistoryAdminSend from './MessagesHistoryAdminSend.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,

    MessagesHistoryAdminReceive,
    MessagesHistoryAdminSend,
  },
  data() {
    return {
      tabs: [
        { title: '수신함', isLoaded: false, component: MessagesHistoryAdminReceive },
        { title: '발신함', isLoaded: false, component: MessagesHistoryAdminSend },
      ],
    }
  },
  methods: {
    handleTabChange(activeTabIndex) {
      const activeTab = this.tabs[activeTabIndex]
      if (activeTab && !activeTab.isLoaded) {
        activeTab.isLoaded = true
      }
    },
  },
}
</script>
